/*    
<summary>
   This class component is all about Managing vehicle data functionality.
   Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import UrlConstants from "../../constants/url.constant";
import * as services from "../service/base-service";
import { IObservableInitialState } from "../../models/ICommon";
import { demoVehicle } from "./data";
import moment from "moment";
import {
  DATE_FORMAT_BACKEND,
  DATE_TIME_FORMAT_FILENAME,
  DATE_TIME_FORMAT_TO_BACKEND,
  emptyValue,
} from "../../constants/common-constant";
import { getVehicleListState } from "../initialState/get-vehicle-list-state";
import { AllocationPeriodEnum } from "../../constants/enums/allocation-period-enum";
import { addEditVehicleState as initialState } from "../initialState/add-edit-vehicle-state";
import {
  IGetVehicleList,
  IVehicle,
  IVehicleList,
  IAddEditVehicle,
  IVehicleCSVImportForm,
} from "../../models/response/IVehicleResponse";
import {
  getClientId,
  getClientLimitSetting,
  getEnableLimitSetting,
  getUserType,
} from "../../helpers/localStorages.helper";
import { IVehicleState } from "../../models/state/IVehicleState";
import { vehicleInitialFilterState } from "../initialState/initial-filter-states";
import {
  IVehicleFilter,
  IVehicleInitialFilterState,
} from "../../models/IFilter";
import { toast } from "react-toastify";
import { formatMessage } from "../../translations/formatMessage";
import { ErrorMessage } from "../../constants/error.constant";
import { ca } from "date-fns/locale";
import UserType from "../../constants/userType.constant";

export class VehicleStore implements IVehicleState {
  inProgress = false;
  error = "";
  vehicleList: IGetVehicleList = getVehicleListState;

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  addUpdateVehicleState: IObservableInitialState = {
    ...this.initialStateValue,
  };
  DeleteVehicleState: IObservableInitialState = { ...this.initialStateValue };

  vehicleDetail: IVehicle | undefined = undefined;
  vehicleDetailState: IObservableInitialState = { ...this.initialStateValue };

  filter: IVehicleFilter = {
    ...vehicleInitialFilterState,
  };

  addVehicleCSVState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      error: observable,
      vehicleList: observable,
      addUpdateVehicleState: observable,
      DeleteVehicleState: observable,
      vehicleDetail: observable,
      vehicleDetailState: observable,
      addVehicleCSVState: observable,
      filter: observable,
      GetVehicleListService: action,
      GetVehicleByIdService: action,
      AddVehicleService: action,
      UpdateVehicleService: action,
      DeleteVehicleService: action,
      addVehicleCSVService: action,
      reset: action,
      resetStore: action,
      resetGetVehicleDetail: action,
      resetAddUpdateVehicleState: action,
      resetAddVehicleCSVState: action,
      resetDeleteVehicleState: action,
      setFilterDetail: action,
      downloadLog: action,
      generateLogContent: action,
      getVehicleDetail: computed,
      getVehicleList: computed,
    });
  }

  GetVehicleListService = (
    currentPage: number,
    pagerSize: number,
    orderBy: string,
    column: string,
    filter: IVehicleInitialFilterState
  ) => {
    this.inProgress = true;
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url =
      `${UrlConstants.GetVehicleList}?clientId=${clientIdFromLocalStorage}` +
      "&pageNo=" +
      currentPage +
      "&pageSize=" +
      pagerSize +
      "&orderBy=" +
      orderBy +
      "&column=" +
      column;
    const requestBody = {
      ...filter,
      RegistrationExpiryDateInDays:
        filter.RegistrationExpiryDateInDays == -1
          ? null
          : filter.RegistrationExpiryDateInDays,
    };
    return services
      .post(url, { ...requestBody })
      .then((response: IApiResponse<IApiSuccessResponse<IGetVehicleList>>) => {
        this.vehicleList = response.data.Data;
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get getVehicleList(): IVehicleList[] {
    if (this.vehicleList?.Vehicles && this.vehicleList.Vehicles?.length > 0) {
      return this.vehicleList.Vehicles.map((vehicle: IVehicle) => {
        let IsDelete: boolean = true;
        let IsEdit: boolean = true;
        return {
          ...vehicle,
          Id: vehicle.VehicleId,
          RFID: vehicle.RFID ? vehicle.RFID : emptyValue,
          Description: vehicle.Description ? vehicle.Description : emptyValue,
          RegistrationExpiryDate: moment(vehicle.RegistrationExpiryDate).format(
            DATE_FORMAT_BACKEND
          ),
          AllocationPeriod: AllocationPeriodEnum[vehicle.AllocationPeriod],
          CustomerName: vehicle.CustomerName
            ? vehicle.CustomerName
            : emptyValue,
          BelongToCustomer: vehicle?.BelongToCustomer ? "true" : "false",
          IsDeletable: IsDelete,
          IsEditable: IsEdit,
        };
      });
    }
    return [];
  }

  GetVehicleByIdService = (id: number) => {
    this.vehicleDetailState.inProgress = true;
    // const tempDetail = (this.vehicleList.Vehicles && this.vehicleList.Vehicles.length) > 0 ? this.vehicleList.Vehicles.filter((d:IVehicle)=>d.VehicleId === id) : [];
    // this.vehicleDetail =
    //   tempDetail.length > 0 ? { ...tempDetail[0] } : undefined;
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.GetVehicleById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
    return services
      .get(url)
      .then((response: IApiResponse<IApiSuccessResponse<IVehicle>>) => {
        this.vehicleDetail = response.data.Data;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
        // this.vehicleDetailState.error = err;
      })
      .finally(
        action(() => {
          this.vehicleDetailState.inProgress = false;
        })
      );
  };

  get getVehicleDetail(): IAddEditVehicle {
    let clientSetting = getEnableLimitSetting();
    let defaultValues = {
      RemainingVolume: "0",
      AllocationVolume: "0",
      AllocationPerFill: "0",
      AllocationPeriod: 0,
    };
    let tempValues = {
      ...initialState,
      RegistrationExpiryDate: moment(new Date()).format(DATE_FORMAT_BACKEND),
    };
    if (this.vehicleDetail) {
      tempValues = {
        ...this.vehicleDetail,
        RegistrationExpiryDate: this.vehicleDetail?.RegistrationExpiryDate
          ? moment(this.vehicleDetail?.RegistrationExpiryDate).format(
              DATE_FORMAT_BACKEND
            )
          : moment(new Date()).format(DATE_FORMAT_BACKEND),
        RemainingVolume:
          this.vehicleDetail?.RemainingVolume != null
            ? this.vehicleDetail.RemainingVolume.toString()
            : "",
        AllocationVolume:
          this.vehicleDetail?.AllocationVolume != null
            ? this.vehicleDetail.AllocationVolume.toString()
            : "",
        AllocationPerFill:
          this.vehicleDetail?.AllocationPerFill != null
            ? this.vehicleDetail.AllocationPerFill.toString()
            : "",
        AllocationPeriod:
          this.vehicleDetail?.AllocationPeriod != null
            ? this.vehicleDetail.AllocationPeriod
            : 0,
        TruckAllocation1:
          this.vehicleDetail?.TruckAllocation1 != null
            ? this.vehicleDetail.TruckAllocation1
            : "",
        UpdatedAt: this.vehicleDetail?.UpdatedAt
          ? moment(this.vehicleDetail?.UpdatedAt).format(
              DATE_TIME_FORMAT_TO_BACKEND
            )
          : "",
        CustId4: this.vehicleDetail?.CustId4 ? this.vehicleDetail?.CustId4 : "",
        HigherConsumptionLimit:
          this.vehicleDetail?.HigherConsumptionLimit != null
            ? this.vehicleDetail?.HigherConsumptionLimit
            : "",
        LowerConsumptionLimit:
          this.vehicleDetail?.LowerConsumptionLimit != null
            ? this.vehicleDetail?.LowerConsumptionLimit
            : "",
      };
    }
    if (!clientSetting) tempValues = { ...tempValues, ...defaultValues };
    return tempValues;
  }

  resetGetVehicleDetail = () => {
    this.vehicleDetail = undefined;
    this.vehicleDetailState = { ...this.initialStateValue };
  };

  AddVehicleService = (id: number, data: IAddEditVehicle) => {
    this.addUpdateVehicleState.inProgress = true;
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    let url = `${UrlConstants.AddVehicle}`;
    let tempValues = { ...data, RemainingVolume: data.AllocationVolume };
    tempValues.RegistrationExpiryDate =
      moment(data.RegistrationExpiryDate).format(DATE_FORMAT_BACKEND) +
      "T23:59:59.000Z";
    return services
      .post(url, { ...tempValues, ClientId: clientIdFromLocalStorage })
      .then(() => {
        this.addUpdateVehicleState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateVehicleState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateVehicleState.inProgress = false;
        })
      );
  };

  UpdateVehicleService = (id: number, data: IAddEditVehicle) => {
    let isCustomerAdmin = getUserType() === UserType.CustomerAdmin;
    this.addUpdateVehicleState.inProgress = true;
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.UpdateVehicle}?id=${id}&clientId=${clientIdFromLocalStorage}`;
    delete data.CustomerName;
    let tempValues = { ...data };
    tempValues.RegistrationExpiryDate = data.RegistrationExpiryDate
      ? moment(data.RegistrationExpiryDate).format(DATE_FORMAT_BACKEND) +
        "T23:59:59.000Z"
      : "";
    tempValues.HigherConsumptionLimit = data.HigherConsumptionLimit
      ? data.HigherConsumptionLimit
      : "";
    tempValues.LowerConsumptionLimit = data.LowerConsumptionLimit
      ? data.LowerConsumptionLimit
      : "";
    return services
      .put(url, { ...tempValues })
      .then(() => {
        this.addUpdateVehicleState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateVehicleState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateVehicleState.inProgress = false;
        })
      );
  };

  addVehicleCSVService = (data: IVehicleCSVImportForm) => {
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    this.addVehicleCSVState.inProgress = true;
    const formData = new FormData();
    if (data.CSVFile) formData.append("file", data.CSVFile); // Append the file to FormData
    return services
      .postMultipart(
        UrlConstants.ImportVehicleCsv + "?clientId=" + clientIdFromLocalStorage,
        formData
      )
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        this.addVehicleCSVState.success = true;
        toast.success(formatMessage("VehicleCsvFileUploaded"));
        this.downloadLog(response.data);
      })
      .catch((err: any) => {
        if (err.message && err.data) {
          switch (err.message) {
            case ErrorMessage.AllRecordsDuplicate:
              toast.error(formatMessage(err.message ?? err));
              this.downloadLog({ Data: err.data });
              break;
            case ErrorMessage.SomeRecordsDuplicate:
              toast.info(formatMessage(err.message ?? err));
              this.downloadLog({ Data: err.data });
              this.addVehicleCSVState.success = true;
              break;
            case ErrorMessage.ErrorLog:
              toast.error(formatMessage(err.message ?? err));
              this.downloadErrorLog(err.data);
              break;
            default:
              break;
          }
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addVehicleCSVState.inProgress = false;
        })
      );
  };

  downloadErrorLog = (data: any) => {
    const currentDate = new Date();
    const splittedData = data.split("Row");
    const logContent = `${splittedData
      .map(
        (item: any, index: number) =>
          `${index > 0 ? `Row ${item}\n` : `${item}\n`}`
      )
      .join("")}`;
    const blob = new Blob([logContent], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download =
      moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) +
      "vehicles log.txt";
    a.click();
    URL.revokeObjectURL(a.href);
  };

  downloadLog = (data: any) => {
    const currentDate = new Date();
    const logContent = this.generateLogContent(data.Data);
    const blob = new Blob([logContent], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download =
      moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) +
      "vehicles log.txt";
    a.click();
    URL.revokeObjectURL(a.href);
  };

  generateLogContent = (data: any) => {
    const currentDate = new Date();

    const generateErrorList = (
      values: string[] | number,
      itemsPerLine: number = 5
    ) => {
      if (!Array.isArray(values) || values.length === 0) {
        return "None";
      }

      const formattedList = values.map((item, index) => {
        const separator = (index + 1) % itemsPerLine === 0 ? "\n" : ", ";
        return `${item}${separator}`;
      });

      return formattedList.join("").trim();
    };

    const logContent = `
Log generated on: ${moment(currentDate).format(DATE_TIME_FORMAT_TO_BACKEND)}
Total Vehicles Count (To be added): ${data.TotalVehiclesCount}
Vehicles Added: ${data.TotalVehiclesAdded}
Vehicles Failed: ${data.TotalFailedRecord}

**Errors**
**Records with Duplicate Registration Number: (Count: ${
      data.TotalDuplicateRegNo
    }) ** :
 ${
   data?.DuplicateRegNo?.length > 0 ? generateErrorList(data.DuplicateRegNo) : 0
 }
 **Records with Duplicate RFID: (Count: ${data.TotalDuplicateRFID}) ** :
 ${
   data?.DuplicateRFIDs?.length > 0 ? generateErrorList(data.DuplicateRFIDs) : 0
 }
`;

    return logContent;
  };

  resetAddVehicleCSVState = () => {
    this.addVehicleCSVState = { ...this.initialStateValue };
  };

  resetAddUpdateVehicleState = () => {
    this.addUpdateVehicleState = { ...this.initialStateValue };
  };

  DeleteVehicleService = (id: number) => {
    this.DeleteVehicleState.inProgress = true;
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.DeleteVehicle}?id=${id}&clientId=${clientIdFromLocalStorage}`;
    return services
      .deleteapi(url)
      .then(() => {
        this.DeleteVehicleState.success = true;
      })
      .catch((err: string) => {
        this.DeleteVehicleState.error = err;
      })
      .finally(
        action(() => {
          this.DeleteVehicleState.inProgress = false;
        })
      );
  };

  /*
    This function is used to set the filter details to the Filter observable.  
    */
  setFilterDetail = (filter: IVehicleFilter) => {
    this.filter = { ...filter };
  };

  resetDeleteVehicleState = () => {
    this.DeleteVehicleState = { ...this.initialStateValue };
  };

  reset = () => {
    this.error = "";
    this.inProgress = false;
  };

  resetError = () => {
    this.error = "";
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.vehicleList = getVehicleListState;

    this.vehicleDetail = undefined;
    this.vehicleDetailState = { ...this.initialStateValue };

    this.addUpdateVehicleState = { ...this.initialStateValue };

    this.DeleteVehicleState = { ...this.initialStateValue };
  };
}

export default new VehicleStore();
