/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";
import {
  DATE_FORMAT_BACKEND,
  integerRegex,
} from "../../../constants/common-constant";
import { IOption, IOptionWithLimit } from "../../../models/ICommon";
import { IUser } from "../../../models/IUser";
import { IAddEditVehicle } from "../../../models/response/IVehicleResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { getVehicleValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import { formatMessage } from "../../../translations/formatMessage";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";
import { getUserType } from "../../../helpers/localStorages.helper";
import UserType from "../../../constants/userType.constant";

interface IProps {
  id: number;
  submitHandler: (data: IAddEditVehicle) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditVehicle;
  allocationPeriodTypes: IOption[];
  customerIdList: IOptionWithLimit[];
  isLimitSettingEnabled: boolean;
}

const AddUpdateVehicleForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
    allocationPeriodTypes,
    customerIdList,
    isLimitSettingEnabled,
  } = props;

  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<IAddEditVehicle>();

    useEffect(() => {
      if (!values.BelongToCustomer) {
        setFieldValue("CustomerId", "-1");
      }
    }, [values.BelongToCustomer]);
    return null;
  };

  return (
    <>
      <Modal
        className="add-user-modal "
        show={true}
        centered
        size="lg"
        dialogAs={DraggableModal}
      >
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_vehicle" />
            ) : (
              <FormattedMessage id="update_vehicle" />
              // <>
              //   <FormattedMessage id="update_vehicle" />&nbsp;
              //   {
              //     initialValues.UpdatedAt &&
              //     <span className="ml-2">(<FormattedMessage id="last_updated" />: {initialValues.UpdatedAt})</span>
              //   }
              // </>
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={getVehicleValidateSchema(
            new Date(),
            isLimitSettingEnabled,
            customerIdList
          )}
          onSubmit={async (values: any) => {
            let RegistrationExpiryDate =
              new Date(values.RegistrationExpiryDate?.$d).toString() !==
              "Invalid Date"
                ? moment(values.RegistrationExpiryDate.$d).format(
                    DATE_FORMAT_BACKEND
                  )
                : values.RegistrationExpiryDate;
            let tempValues = {
              RFID: values.RFID ? values.RFID.trim() : values.RFID,
              Description: values.Description
                ? values.Description.trim()
                : values.Description,
              RegistrationNo: values.RegistrationNo
                ? values.RegistrationNo.trim()
                : values.RegistrationNo,
              RegistrationExpiryDate: RegistrationExpiryDate,
              TruckAllocation1: values.TruckAllocation1,
              TruckAllocation: values.TruckAllocation,
              AllocationPerFill: values.AllocationPerFill,
              AllocationPeriod:
                values.AllocationPeriod === -1 ? 0 : values.AllocationPeriod,
              AllocationVolume: values.AllocationVolume,
              RemainingVolume: values.RemainingVolume,
              FuelDeliveryEnabled: values.FuelDeliveryEnabled,
              LowerConsumptionLimit: values.LowerConsumptionLimit,
              HigherConsumptionLimit: values.HigherConsumptionLimit,
              CustId2: values.CustId2 ? values.CustId2.trim() : values.CustId2,
              CustId4: values.CustId4 ? values.CustId4.trim() : values.CustId4,
              CustId5: values.CustId5 ? values.CustId5.trim() : values.CustId5,
              CustId6: values.CustId6 ? values.CustId6.trim() : values.CustId6,
              BelongToCustomer: values.BelongToCustomer,
              CustomerId: values.CustomerId,
              CustomerName: values.CustomerName ? values.CustomerName : null,
              IsLimitEnabled:
                isLimitSettingEnabled &&
                (customerIdList.filter(
                  (item) => Number(item.Id) === Number(values.CustomerId)
                )[0]
                  ? customerIdList.filter(
                      (item) => Number(item.Id) === Number(values.CustomerId)
                    )[0].EnableLimitSettings
                  : false),
            };
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="rfid"
                      />
                      <Field
                        data-testid="testRFID"
                        className="form-control"
                        name="RFID"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        invalidcharacter={[" "]}
                        placeholder="rfid"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="description"
                      />
                      <Field
                        data-testid="testDescription"
                        className="form-control"
                        name="Description"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        type="text"
                        placeholder="description"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="registration_no"
                      />
                      <Field
                        data-testid="testRegistrationNo"
                        className="form-control"
                        name="RegistrationNo"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        placeholder="registration_no"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group date-picker-error ">
                      <Label
                        className="form-label"
                        // required={true}
                        label="registration_expiry_date"
                      />
                      <Field
                        name="RegistrationExpiryDate"
                        as={FormikDateFieldMUI}
                        min={moment(new Date()).format(DATE_FORMAT_BACKEND)}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                      {/* <Field
                        data-testid="testRegistrationExpiryDate"
                        className="form-control"
                        name="RegistrationExpiryDate"
                        type="text"
                        mindate={new Date()}
                        placeholder="registration_expiry_date"
                        as={FormikFormDatePicker}
                      /> */}
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label className="form-label" label="truck_allocation" />
                      <Field
                        data-testid="testTruckAllocation"
                        className="form-control"
                        name="TruckAllocation1"
                        placeholder="truck_allocation"
                        as={FormikFormInput}
                        invalidcharacter={[" "]}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  {isLimitSettingEnabled &&
                    (customerIdList.filter(
                      (item) => Number(item.Id) === Number(values.CustomerId)
                    )[0]
                      ? customerIdList.filter(
                          (item) =>
                            Number(item.Id) === Number(values.CustomerId)
                        )[0].EnableLimitSettings
                      : false) && (
                      <>
                        <Col lg={4} md={6} xs={12}>
                          <div className="mb-3 form-group">
                            <Label
                              className="form-label"
                              required={true}
                              label="allocation_per_fill"
                            />
                            <Field
                              data-testid="testAllocationPerFill"
                              className="form-control"
                              name="AllocationPerFill"
                              placeholder="allocation_per_fill"
                              regex={integerRegex}
                              as={FormikFormInputForRegexCheck}
                              disabled={
                                getUserType() === UserType.CustomerAdmin
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                          <div className="mb-3 form-group">
                            <Label
                              className="form-label"
                              required={true}
                              label="allocation_period"
                            />
                            <Field
                              name="AllocationPeriod"
                              className="form-control"
                              type="number"
                              as={FormikFormSelectField}
                              typeofoptionid="number"
                              options={allocationPeriodTypes}
                              disabled={
                                getUserType() === UserType.CustomerAdmin
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                          <div className="mb-3 form-group">
                            <Label
                              className="form-label"
                              required={true}
                              label="allocation_volume"
                            />
                            <Field
                              data-testid="testAllocationVolume"
                              className="form-control"
                              name="AllocationVolume"
                              placeholder="allocation_volume"
                              regex={integerRegex}
                              as={FormikFormInputForRegexCheck}
                              disabled={
                                getUserType() === UserType.CustomerAdmin
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                          <div className="mb-3 form-group">
                            <Label
                              className="form-label"
                              label="remaining_volume"
                            />
                            <Field
                              data-testid="testRemainingVolume"
                              className="form-control"
                              name="RemainingVolume"
                              placeholder="remaining_volume"
                              value={
                                props.id === -1
                                  ? values.AllocationVolume
                                  : values.RemainingVolume
                              }
                              regex={integerRegex}
                              disabled={true}
                              as={FormikFormInputForRegexCheck}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="lower_consumption_limit"
                      />
                      <Field
                        data-testid="testLowerConsumptionLimit"
                        className="form-control"
                        name="LowerConsumptionLimit"
                        placeholder="lower_consumption_limit"
                        regex={integerRegex}
                        as={FormikFormInputForRegexCheck}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="higher_consumption_limit"
                      />
                      <Field
                        data-testid="testHigherConsumptionLimit"
                        className="form-control"
                        name="HigherConsumptionLimit"
                        placeholder="higher_consumption_limit"
                        regex={integerRegex}
                        as={FormikFormInputForRegexCheck}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  {values.BelongToCustomer && (
                    <Col lg={4} md={6} xs={12}>
                      <div className="mb-3 form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="customer_id"
                        />
                        <Field
                          className="form-control form-select"
                          aria-label="Default select example"
                          name="CustomerId"
                          type="number"
                          as={FormikFormSelectField}
                          typeofoptionid="string"
                          options={customerIdList}
                          disabled={getUserType() === UserType.CustomerAdmin}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="cust_id_2"
                      />
                      <Field
                        data-testid="testCustId2"
                        className="form-control"
                        name="CustId2"
                        type="text"
                        // invalidcharacter={[]}
                        validcharacter={["@", "#", "$", "-", "_", ".", " "]}
                        placeholder="cust_id_2"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="cust_id_4"
                      />
                      <Field
                        data-testid="testCustId4"
                        className="form-control"
                        name="CustId4"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", ".", " "]}
                        // invalidcharacter={[" "]}
                        placeholder="cust_id_4"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="cust_id_5"
                      />
                      <Field
                        data-testid="testCustId5"
                        className="form-control"
                        name="CustId5"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", ".", " "]}
                        // invalidcharacter={[" "]}
                        placeholder="cust_id_5"
                        as={FormikFormInput}
                        disabled={getUserType() === UserType.CustomerAdmin}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="cust_id_6"
                      />
                      <Field
                        data-testid="testCustId6"
                        className="form-control"
                        name="CustId6"
                        type="text"
                        // invalidcharacter={[" "]}
                        validcharacter={["@", "#", "$", "-", "_", ".", " "]}
                        placeholder="cust_id_6"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-group switchBox">
                      <div className="form-check form-switch">
                        <Field
                          data-testid="testRememberMe"
                          className="form-check-input"
                          name="FuelDeliveryEnabled"
                          type="checkbox"
                          id="FuelDeliveryEnabled"
                          disabled={getUserType() === UserType.CustomerAdmin}
                        />
                        <label
                          htmlFor="FuelDeliveryEnabled"
                          className="form-check-label mt-1"
                        >
                          <FormattedMessage id="fuel_delivery_enabled" />
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-group witchBox">
                      <div className="form-check form-switch">
                        <Field
                          data-testid="testRememberMe"
                          className="form-check-input"
                          name="BelongToCustomer"
                          type="checkbox"
                          id="Self"
                          disabled={
                            getUserType() === UserType.CustomerAdmin
                              ? true
                              : customerIdList.length <= 1
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="Self" className="form-check-label mt-1">
                          <FormattedMessage id="belongs_to_customer" />
                        </label>
                        {customerIdList.length <= 1 && (
                          <div className="d-inline p-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-info-circle ml-2"
                              viewBox="0 0 16 16"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-html={formatMessage(
                                "no_customer_available"
                              )}
                              data-tooltip-variant="dark"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <Tooltip id="my-tooltip" place="right" />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateVehicleForm;
